import Vue from 'vue'
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Axios from 'axios';
import {format} from 'silly-datetime';
import App from './App.vue'
import Login from "@/components/Login";
import Plan from "@/components/Plan";
import PlanList from "@/components/PlanList";
import PlanEditor from "@/components/PlanEditor";
import LogEditor from "@/components/LogEditor";
import qs from "qs";

Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(ElementUI);
let axios = Axios.create({
    // baseURL: 'http://localhost:8080'
    baseURL: 'https://worktime.eshyp.com'
});
axios.interceptors.request.use(config => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers.token = token;
    }
    return config;
}, err => {
    return Promise.reject(err);
})

axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
axios.defaults.transformRequest = data => qs.stringify(data);

axios.interceptors.response.use(response => {
    if (response.data.code) {
        switch (response.data.code) {
            case 1:
                home.$message.error(response.data.msg);
                break;
            case 2:
                router.push('/login');
                break
        }
    }
    return response;
}, err => {
    home.$message.error("系统故障请稍后再试");
    return Promise.reject(err);
})

Vue.prototype.axios = axios;
Vue.prototype.formatdate = format;

const router = new VueRouter({
    mode: 'history',
    routes: [
        {path: '/', redirect: '/login'},
        {path: '/login', component: Login},
        {
            path: '/plan', component: Plan, children: [
                {path: 'list', component: PlanList},
                {path: 'editor', component: PlanEditor},
                {path: 'log', component: LogEditor},
            ]
        }
    ]
});


const home = new Vue({
    render: h => h(App),
    router
}).$mount('#app')


