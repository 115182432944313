<template>
  <el-main>
    <el-row>
      <el-col :span="24">
        <el-form :inline="true" :model="form">
          <el-form-item label="时间范围">
            <el-date-picker
                v-model="form.dateRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="人员" v-if="showUser">
            <el-select v-model="form.user_id" placeholder="全部" clearable>
              <el-option v-for="item in options.user" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目">
            <el-select v-model="form.project_id" placeholder="全部" clearable>
              <el-option v-for="item in options.project" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row v-if="showAction">
      <el-col :span="24">
        <el-form :inline="true" :model="form">
          <el-form-item>
            <el-button type="primary" @click="addPlan">添加计划</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="addLog">添加计划外工作</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table :summary-method="sum"
              show-summary
              :data="tableData"
              style="width: 100%">
      <el-table-column
          prop="date"
          label="日期"
          width="120"
      >
      </el-table-column>
      <el-table-column
          prop="user"
          label="人员"
          width="100" v-if="showUser"
      >
      </el-table-column>
      <el-table-column
          prop="project"
          label="项目">
      </el-table-column>
      <el-table-column
          prop="work"
          label="工作类型">
      </el-table-column>
      <el-table-column
          prop="work_content"
          label="工作内容"
          width="400">
      </el-table-column>
      <el-table-column
          prop="plan_time"
          label="计划工时">
      </el-table-column>
      <el-table-column
          prop="real_time"
          label="实际工时">
      </el-table-column>
      <el-table-column
          prop="is_finish_cn"
          label="是否完成">
      </el-table-column>
      <el-table-column
          prop="progress"
          label="进度">
      </el-table-column>
      <el-table-column
          prop="problem_type"
          label="问题类型">
      </el-table-column>
      <el-table-column
          prop="problem_content"
          label="问题描述">
      </el-table-column>
      <el-table-column
          label="编辑" align="right" v-if="showAction">
        <template slot-scope="scope">
          <el-button @click="edit(scope)">编辑</el-button>
          <el-button @click="log(scope)">填报</el-button>
          <el-button @click="del(scope)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-main>
</template>

<script>
export default {
  name: "PlanList",
  created() {
    let me = this;
    if (localStorage.getItem("role") == 1) {
      this.form.dateRange = [new Date(), new Date()];
      this.showUser = false;
      this.showAction = true;
    } else if (localStorage.getItem("role") == 99) {
      let yesterday = new Date(new Date().getTime() - 86400000);
      this.form.dateRange = [yesterday, yesterday];
      this.showUser = true;
      this.showAction = false;
    }
    this.axios.get('/api/config/list').then((response) => {
      me.options = response.data.data;
      response.data.data.user.forEach(val => {
        me.mappings.user["id_" + val.id] = val.name;
      })
      response.data.data.project.forEach(val => {
        me.mappings.project["id_" + val.id] = val.name;
      })
      response.data.data.work.forEach(val => {
        me.mappings.work["id_" + val.id] = val.name;
      })
      response.data.data.problem.forEach(val => {
        me.mappings.problem["id_" + val.id] = val.name;
      })
      me.search();
    })
  },
  data() {
    return {
      mappings: {
        user: {},
        project: {},
        work: {},
        problem: {}
      },
      options: {
        user: [],
        project: [],
        work: [],
        problem: []
      },
      form: {
        dateRange: null,
        user_id: null,
        project_id: null,
        work_type_id: null,
        problem_id: null
      },
      rawData: [],
      showUser: false,
      showAction: true
    }
  },
  computed: {
    tableData() {
      let me = this;
      this.rawData.forEach(val => {
        val['date'] = val.date.substr(0, 10);
        val['user'] = me.mappings.user["id_" + val.user_id];
        val['project'] = me.mappings.project["id_" + val.project_id];
        val['work'] = me.mappings.work["id_" + val.work_type_id];
        val['problem'] = me.mappings.problem["id_" + val.problem_id];
        if (val.is_finish != null) {
          val['is_finish_cn'] = val.is_finish ? '是' : '否';
        }
      });
      return this.rawData;
    }
  },
  methods: {
    search() {
      let me = this;
      let params = {};
      if (this.form.dateRange) {
        params['date_min'] = this.formatdate(this.form.dateRange[0], 'YYYY-MM-DD');
        params['date_max'] = this.formatdate(this.form.dateRange[1], 'YYYY-MM-DD');
      }
      if (this.form.user_id) {
        params['user_id'] = this.form.user_id;
      }
      if (this.form.project_id) {
        params['project_id'] = this.form.project_id;
      }
      console.log(params);
      this.axios.get('/api/plan/list', {
        params
      }).then(response => {
        me.rawData = response.data.data;
      })
    },
    edit(scope) {
      this.$router.push('/plan/editor?id=' + scope.row.id);
    },
    log(scope) {
      this.$router.push('/plan/log?id=' + scope.row.id);
    },
    del(scope) {
      let me = this;
      this.axios.post('/api/plan/delete', {
        id: scope.row.id
      }).then(res => {
        console.log(res);
        me.search();
      })
    },
    addPlan() {
      this.$router.push('/plan/editor');
    },
    addLog() {
      this.$router.push('/plan/log');
    },
    sum(param) {
      let me = this;
      const {columns, data} = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if ((index === 4 && !me.showUser) || index === 5 || (index === 6 && me.showUser)) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += ' 小时';
          } else {
            sums[index] = '';
          }
        }

      });

      return sums;
    }
  }
}
</script>

<style scoped>

</style>