<template>
  <el-container>
    <el-header>
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="/plan/list">工时填报</el-menu-item>
        <el-menu-item style="float: right" @click="quit">
          退出系统
        </el-menu-item>
        <el-menu-item style="float: right">
          您好，{{ name }}
        </el-menu-item>
      </el-menu>
    </el-header>
    <router-view></router-view>
  </el-container>
</template>

<script>
export default {
  name: "Plan",
  data() {
    return {
      activeIndex: "/plan/list",
      name: localStorage.getItem('name')
    }
  },
  methods: {
    handleSelect(key) {
      this.$router.push(key);
    },
    quit() {
      localStorage.clear();
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped>

</style>