<template>
  <el-row :gutter="100">
    <el-col :span="6">
      <el-form ref="form" :model="form" label-width="80px" style="margin-top: 50px">
        <el-form-item label="时间">
          <el-date-picker
              v-model="form.date"
              type="date"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="项目">
          <el-select v-model="form.project_id" placeholder="全部" clearable>
            <el-option v-for="item in options.project" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作类型">
          <el-select v-model="form.work_type_id" placeholder="全部" clearable>
            <el-option v-for="item in options.work" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作内容">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请简要说明工作内容"
              v-model="form.work_content">
          </el-input>
        </el-form-item>
        <el-form-item label="计划时间">
          <el-input v-model="form.plan_time" placeholder="单位：小时"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "PlanEditor",
  created() {
    let me = this;
    this.axios.get('/api/config/list').then((response) => {
      Object.assign(me.options, response.data.data);
      let planId = this.$route.query.id;
      if (planId) {
        let params = {
          id: planId
        };
        this.axios.get('/api/plan/get', {
          params
        }).then(response => {
          me.form.id = response.data.data.id;
          me.form.date = response.data.data.date;
          me.form.project_id = response.data.data.project_id;
          me.form.work_type_id = response.data.data.work_type_id;
          me.form.work_content = response.data.data.work_content;
          me.form.plan_time = response.data.data.plan_time;
        })
      } else {
        this.form.date = new Date();
      }
    })

  },
  data() {
    return {
      form: {
        id: null,
        date: null,
        project_id: null,
        work_type_id: null,
        work_content: '',
        plan_time: null
      },
      options: {
        time: [
          {id: 1, name: '1小时'},
          {id: 2, name: '2小时'},
          {id: 3, name: '3小时'},
          {id: 4, name: '4小时'},
          {id: 5, name: '5小时'},
          {id: 6, name: '6小时'},
          {id: 7, name: '7小时'},
          {id: 8, name: '8小时'}
        ],
        project: [],
        work: []
      }
    }
  },
  methods: {
    submit() {
      let planId = this.$route.query.id;
      let params = {};
      params.id = this.form.id;
      params.date = this.formatdate(this.form.date, 'YYYY-MM-DD');
      params.project_id = this.form.project_id;
      params.work_type_id = this.form.work_type_id;
      params.work_content = this.form.work_content;
      params.plan_time = Number(this.form.plan_time);
      if (!params.date) {
        this.$message.error('请填写日期');
        return;
      }
      if (!params.project_id) {
        this.$message.error('请填写项目');
        return;
      }
      if (!params.work_type_id) {
        this.$message.error('请填写工作类型');
        return;
      }
      if (!params.work_content) {
        this.$message.error('请填写工作内容');
        return;
      }
      if (isNaN(params.plan_time)) {
        this.$message.error('计划时间格式不对');
        return;
      }
      if (!params.plan_time) {
        this.$message.error('请填写计划时间');
        return;
      }

      params.plan_time = params.plan_time.toFixed(1);
      if (planId) {
        this.axios.post('/api/plan/update', params).then(() => {
          this.$router.push('/plan/list');
        })
      } else {
        this.axios.post('/api/plan/add', params).then(() => {
          this.$router.push('/plan/list');
        })
      }

    }
  }
}
</script>

<style scoped>

</style>
