<template>
  <el-row :gutter="100">
    <el-col :span="6">
      <el-form ref="form" :model="form" label-width="80px" style="margin-top: 50px">
        <el-form-item label="时间">
          <el-date-picker :disabled="disabled"
                          v-model="form.date"
                          type="date"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="项目">
          <el-select v-model="form.project_id" placeholder="全部" :disabled="disabled">
            <el-option v-for="item in options.project" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作类型">
          <el-select v-model="form.work_type_id" placeholder="全部" :disabled="disabled">
            <el-option v-for="item in options.work" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作内容">
          <el-input :disabled="disabled"
                    type="textarea"
                    :rows="2"
                    placeholder="请简要说明工作内容"
                    v-model="form.work_content">
          </el-input>
        </el-form-item>
        <el-form-item label="计划时间">
          <el-input v-model="form.plan_time" placeholder="单位：小时"></el-input>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="6">
      <el-form ref="form" :model="form" label-width="80px" style="margin-top: 50px">
        <el-form-item label="实际时间">
          <el-input v-model="form.real_time" placeholder="单位：小时"></el-input>
        </el-form-item>
        <el-form-item label="是否完成">
          <el-select v-model="form.is_finish" placeholder="全部" clearable>
            <el-option v-for="item in options.is_finish" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="进度">
          <el-input v-model="form.progress" placeholder="请填写0-100的数字"></el-input>
        </el-form-item>
        <el-form-item label="问题类型">
          <el-select v-model="form.problem_type" placeholder="全部">
            <el-option v-for="item in options.problem" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="问题内容">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请简要说明问题内容"
              v-model="form.problem_content">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>

</template>

<script>
export default {
  name: "LogEditor",
  created() {
    let me = this;
    let id = this.$route.query.id;
    if (id) {
      me.disabled = true;
    } else {
      me.disabled = false;
    }

    this.axios.get('/api/config/list').then((response) => {
      Object.assign(me.options, response.data.data);
      let planId = this.$route.query.id;
      if (planId) {
        let params = {
          id: planId
        };
        this.axios.get('/api/plan/get', {
          params
        }).then(response => {
          me.form.id = response.data.data.id;
          me.form.date = response.data.data.date;
          me.form.project_id = response.data.data.project_id;
          me.form.work_type_id = response.data.data.work_type_id;
          me.form.work_content = response.data.data.work_content;
          me.form.plan_time = response.data.data.plan_time;
          me.form.real_time = response.data.data.real_time;
          me.form.is_finish = response.data.data.is_finish;
          me.form.progress = response.data.data.progress;
          me.form.problem_type = response.data.data.problem_type;
          me.form.problem_content = response.data.data.problem_content;
        })
      } else {
        this.form.date = new Date();
      }
    })

  },
  data() {
    return {
      form: {
        id: null,
        date: null,
        project_id: null,
        work_type_id: null,
        work_content: null,
        plan_time: null,
        real_time: null,
        is_finish: null,
        progress: null,
        problem_type: null,
        problem_content: null
      },
      options: {
        time: [
          {id: 1, name: '1小时'},
          {id: 2, name: '2小时'},
          {id: 3, name: '3小时'},
          {id: 4, name: '4小时'},
          {id: 5, name: '5小时'},
          {id: 6, name: '6小时'},
          {id: 7, name: '7小时'},
          {id: 8, name: '8小时'}
        ],
        is_finish: [
          {id: 1, name: '是'},
          {id: 0, name: '否'}
        ],
        project: [],
        work: [],
        problem: []
      },
      disabled: false
    }
  },
  methods: {
    submit() {
      let planId = this.$route.query.id;
      let params = {};
      params.id = this.form.id;
      params.date = this.formatdate(this.form.date, 'YYYY-MM-DD');
      params.project_id = this.form.project_id;
      params.work_type_id = this.form.work_type_id;
      params.work_content = this.form.work_content;
      params.plan_time = Number(this.form.plan_time);
      params.real_time = Number(this.form.real_time);
      params.is_finish = this.form.is_finish;
      params.progress = this.form.progress;
      params.problem_type = this.form.problem_type;
      params.problem_content = this.form.problem_content;
      if (!params.date) {
        this.$message.error('请填写日期');
        return;
      }
      if (!params.project_id) {
        this.$message.error('请填写项目');
        return;
      }
      if (!params.work_type_id) {
        this.$message.error('请填写工作类型');
        return;
      }
      if (!params.work_content) {
        this.$message.error('请填写工作内容');
        return;
      }
      if (isNaN(params.real_time)) {
        this.$message.error('实际时间格式不对');
        return;
      }
      if (!params.real_time) {
        this.$message.error('请填写实际时间');
        return;
      }
      if (params.is_finish == null) {
        this.$message.error('请填写是否完成');
        return;
      }
      params.real_time = params.real_time.toFixed(1);
      if (planId) {
        this.axios.post('/api/log/update', params).then(() => {
          this.$router.push('/plan/list');
        })
      } else {
        this.axios.post('/api/log/add', params).then(() => {
          this.$router.push('/plan/list');
        })
      }

    }
  }
}
</script>

<style scoped>

</style>
